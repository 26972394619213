.Forecast-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .Forecast-logo {
    animation: Forecast-logo-spin infinite 20s linear;
  }
}

.Forecast-park-name {
  color: lightblue;
  font-size: xx-large;
}

.Forecast-trail-condition {
  color: rgb(188, 107, 107);

}

.Forecast-list {
  color: aqua;
  font-size: small;
}

.Forecast-detail {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: xx-large;
  color: lightgray;
}

.Forecast-graph {
  background-color: white;
}